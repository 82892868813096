import homeStyles from "../Home/styles.module.css";
import React from "react";
import useCart from "../Hooks/useCart";
import { PurchaseLimitation, useSettings } from "../Networking/useSettings";
import { useMe } from "../AppSetup/MeContext";
import { Trans, useTranslation } from "react-i18next";
import { Row } from "antd";
import TrustBox from "../Trustpilot/TrustBox";

const GenericCheckoutRecap = () => {
  const { t } = useTranslation();
  const { totalItems, selectedSub, shouldPurchaseWaitingList } = useCart();
  const {
    membership,
    subsEnabled,
    unlimitedEnabled,
    purchaseLimitation,
    unlimited,
    unlimitedMaxNumberOfDishesPerPeriod,
    trustpilot,
  } = useSettings();
  const { isUnlimitedMember, latestOrder } = useMe();

  const standardAmount = unlimited?.standardAmountInCents
    ? unlimited.standardAmountInCents / 100
    : 300;

  const discountedAmount = unlimited?.discountedAmountInCents
    ? unlimited.discountedAmountInCents / 100
    : 100;

  const discountAmount =
    unlimited?.standardAmountInCents && unlimited.discountedAmountInCents
      ? unlimited.standardAmountInCents / 100 -
        unlimited.discountedAmountInCents / 100
      : 200;

  const singlePurchaseRecap = () => {
    return (
      <div
        className={homeStyles.banner + " " + homeStyles.bannerBox}
        style={{
          marginTop: 24,
          marginBottom: 16,
          backgroundColor: "var(--light-green)",
        }}
      >
        <img
          className={homeStyles.orderIcon}
          src={"/icons/question_white.svg"}
          alt={"question mark"}
        />
        <h1 className={homeStyles.titleSmaller} style={{ fontSize: 22 }}>
          {t("Cosa sto acquistando?")}
        </h1>
        <p className={homeStyles.textLeft}></p>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <Trans i18nKey={"purchaseDetails"} totalItems={totalItems}>
            La tua Box da {{ totalItems }} piatti.
          </Trans>
        </div>
        {!membership ? null : (
          <div
            className={homeStyles.listItem}
            style={{
              alignItems: "flex-start",
              color: "var(--black)",
              fontWeight: 500,
              marginBottom: 0,
            }}
          >
            <img
              src={"/icons/check_green.svg"}
              alt={"check"}
              style={{ marginTop: 4 }}
            />
            <div>
              {membership.freeTrialPeriod ? (
                <>
                  <b>
                    La membership Humamy, gratis per{" "}
                    {membership.freeTrialPeriod.count}{" "}
                    {membership.freeTrialPeriod.unit} e poi {membership.price}€
                    ogni {membership.period.count} {membership.period.unit}.
                  </b>
                  <br />
                  Puoi disdire in qualsiasi momento, senza vincoli o costi.
                  <b>
                    <br />
                    Se disdici prima della fine del periodo di prova non
                    pagherai nulla!
                  </b>
                </>
              ) : (
                <b>
                  La membership Humamy, {membership.price}€ ogni{" "}
                  {membership.period.count} {membership.period.unit}.
                </b>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const subscriptionPurchaseRecap = () => {
    return (
      <div
        className={homeStyles.banner + " " + homeStyles.bannerBox}
        style={{
          marginTop: 24,
          marginBottom: 16,
          backgroundColor: "var(--light-green)",
        }}
      >
        <img
          className={homeStyles.orderIcon}
          src={"/icons/question_white.svg"}
          alt={"question mark"}
        />
        <h1 className={homeStyles.titleSmaller} style={{ fontSize: 22 }}>
          {t("Cosa sto acquistando?")}
        </h1>
        <p className={homeStyles.textLeft}></p>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          La tua Box da {totalItems} piatti ogni {selectedSub?.period.count}{" "}
          settimane.
        </div>
      </div>
    );
  };

  const unlimitedRecap = () => {
    return (
      <div
        className={homeStyles.banner + " " + homeStyles.bannerBox}
        style={{
          marginTop: 24,
          marginBottom: 16,
          backgroundColor: "var(--light-green)",
        }}
      >
        <img
          className={homeStyles.orderIcon}
          src={"/icons/question_white.svg"}
          alt={"question mark"}
        />
        <h1 className={homeStyles.titleSmaller} style={{ fontSize: 22 }}>
          {t("Cosa sto acquistando?")}
        </h1>
        <p className={homeStyles.textLeft}></p>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <div>
            {t("Abbonamento Unlimited")}
            <br />
            {!latestOrder && discountAmount !== 0 ? (
              <span style={{ fontWeight: "normal" }}>
                <Trans
                  i18nKey={"unlimitedOfferNewUsers"}
                  standardAmount={standardAmount}
                  discountedAmount={discountedAmount}
                >
                  {{ discountedAmount }} il primo mese, poi {{ standardAmount }}
                  € / mese.
                </Trans>
              </span>
            ) : (
              <span style={{ fontWeight: "normal" }}>
                {unlimited?.standardAmountInCents
                  ? unlimited.standardAmountInCents / 100
                  : 300}
                € / {t("mese")}.
              </span>
            )}
          </div>
        </div>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <div>
            {purchaseLimitation ===
            PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery
              ? t("Tutti i piatti di cui hai bisogno")
              : unlimitedMaxNumberOfDishesPerPeriod
                ? t("Almeno un pasto al giorno per tutto il mese")
                : t("Quanti piatti vuoi, quando vuoi")}
            <br />
            <span style={{ fontWeight: "normal" }}>
              {purchaseLimitation ===
              PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery
                ? t(
                    "Ordina in base alle tue necessità, in box da 15 piatti e senza costi aggiuntivi. Puoi effettuare il prossimo ordine non appena ricevi la box precedente.",
                  )
                : unlimitedMaxNumberOfDishesPerPeriod
                  ? t(
                      "Ordina fino a 40 piatti al mese in box da 20 piatti l'una.",
                    )
                  : t(
                      "Ordina in base alle tue necessità, in box da 15 piatti e senza costi aggiuntivi.",
                    )}
            </span>
          </div>
        </div>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <div>
            {t("Puoi disdire in qualsiasi momento,")}
            <br />
            <span style={{ fontWeight: "normal" }}>
              {t("senza vincoli o costi.")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const waitingListRecap = () => {
    return (
      <div
        className={homeStyles.banner + " " + homeStyles.bannerBox}
        style={{
          marginTop: 24,
          marginBottom: 16,
          backgroundColor: "var(--light-green)",
        }}
      >
        <img
          className={homeStyles.orderIcon}
          src={"/icons/question_white.svg"}
          alt={"question mark"}
        />
        <h1 className={homeStyles.titleSmaller} style={{ fontSize: 22 }}>
          {t("Cosa sto acquistando?")}
        </h1>
        <p className={homeStyles.textLeft}></p>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <div>
            {t("L'anticipo per il tuo abbonamento")}
            <br />
            <span style={{ fontWeight: "normal" }}>
              {t(
                "10€ adesso, per entrare in lista d’attesa. Quando entrerai in Humamy:",
              )}{" "}
              {unlimited?.discountedAmountInCents
                ? unlimited?.discountedAmountInCents / 100 - 10
                : 90}
              € {t("il primo mese, poi")}{" "}
              {unlimited?.standardAmountInCents
                ? unlimited.standardAmountInCents / 100
                : 300}
              € {t("/ mese.")},
            </span>
          </div>
        </div>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <div>
            {purchaseLimitation ===
            PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery
              ? t("Tutti i piatti di cui hai bisogno")
              : unlimitedMaxNumberOfDishesPerPeriod
                ? t("Almeno un pasto al giorno per tutto il mese")
                : t("Quanti piatti vuoi, quando vuoi")}
            <br />
            <span style={{ fontWeight: "normal" }}>
              {purchaseLimitation ===
              PurchaseLimitation.CannotOrderBeforePreviousBoxDelivery
                ? t(
                    "Ordina in base alle tue necessità, in box da 15 piatti e senza costi aggiuntivi. Puoi effettuare il prossimo ordine non appena ricevi la box precedente.",
                  )
                : unlimitedMaxNumberOfDishesPerPeriod
                  ? t(
                      "Ordina fino a 40 piatti al mese in box da 20 piatti l'una.",
                    )
                  : t(
                      "Ordina in base alle tue necessità, in box da 15 piatti e senza costi aggiuntivi.",
                    )}
            </span>
          </div>
        </div>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <div>
            {t("Puoi disdire in qualsiasi momento,")}
            <br />
            <span style={{ fontWeight: "normal" }}>
              {t("senza vincoli o costi.")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const unlimitedOrder = () => {
    return (
      <div
        className={homeStyles.banner + " " + homeStyles.bannerBox}
        style={{
          marginTop: 24,
          marginBottom: 16,
          backgroundColor: "var(--light-green)",
        }}
      >
        <img
          className={homeStyles.orderIcon}
          src={"/icons/question_white.svg"}
          alt={"question mark"}
        />
        <h1 className={homeStyles.titleSmaller} style={{ fontSize: 22 }}>
          {t("Stiamo preparando il tuo ordine!")}
        </h1>
        <div
          className={homeStyles.listItem}
          style={{ alignItems: "flex-start", color: "var(--black)" }}
        >
          <img
            src={"/icons/check_green.svg"}
            alt={"check"}
            style={{ marginTop: 4 }}
          />
          <div>
            {t("Sei un membro unlimited:")}
            <br />
            <span style={{ fontWeight: "normal" }}>
              {t("non è necessario alcun pagamento.")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {trustpilot ? (
        <Row justify={"center"} style={{ height: "40px", marginTop: 24 }}>
          <TrustBox
            locale={trustpilot.locale}
            templateId={trustpilot.checkoutTemplateId}
          />
        </Row>
      ) : null}
      {!subsEnabled && !unlimitedEnabled && singlePurchaseRecap()}
      {subsEnabled && subscriptionPurchaseRecap()}
      {unlimitedEnabled &&
        !isUnlimitedMember &&
        !shouldPurchaseWaitingList &&
        unlimitedRecap()}
      {unlimitedEnabled &&
        !isUnlimitedMember &&
        shouldPurchaseWaitingList &&
        waitingListRecap()}
      {unlimitedEnabled && isUnlimitedMember && unlimitedOrder()}
    </>
  );
};

export default GenericCheckoutRecap;
