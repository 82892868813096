import styles from "./styles.module.css";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, InputRef, Tooltip } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface FiltersBarProps {
  setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  filters: string[];
  selectedFilters: string[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  onAddedFilter?: (f: string) => void;
  onRemovedFilter?: (f: string) => void;
}

const FiltersBar = ({
  setFilter,
  filters,
  selectedFilters,
  setSelectedFilters,
  onAddedFilter,
  onRemovedFilter,
}: FiltersBarProps) => {
  const [searchClosed, setSearchClosed] = useState(true);
  const [filtersBarSearchForm] = Form.useForm();
  const { Search } = Input;
  const [fakeLoading, setFakeLoading] = useState<boolean | undefined>();
  const inputRef = useRef<InputRef>(null);

  const _setSelectedFilters = (filters: string[]) => {
    const addedFilters = filters.filter((f) => !selectedFilters.includes(f));
    const removedFilters = selectedFilters.filter((f) => !filters.includes(f));

    addedFilters.forEach((f) => {
      if (onAddedFilter) {
        onAddedFilter(f);
      }
    });
    removedFilters.forEach((f) => {
      if (onRemovedFilter) {
        onRemovedFilter(f);
      }
    });
    setSelectedFilters(filters);
  };

  const toggleFilter = (filter: string) => {
    if (selectedFilters.includes(filter)) {
      _setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      _setSelectedFilters([...selectedFilters, filter]);
    }
  };

  interface FilterPillProps {
    label: string;
    onClick: () => void;
    selected: boolean;
  }

  const FilterPill = ({
    label: labelWithDescription,
    onClick,
    selected,
  }: FilterPillProps) => {
    const [label, description] = labelWithDescription.split("*");
    const { t } = useTranslation();
    return (
      <div
        onClick={onClick}
        className={styles.filterPill + " " + (selected && styles.selected)}
        style={{ display: "flex" }}
      >
        <Tooltip title={description}>
          {label}
          {t(description) ? "*" : null}
        </Tooltip>
        <CloseOutlined
          style={
            selected
              ? { marginLeft: 8, fontSize: 14, opacity: 1 }
              : {
                  marginLeft: 0,
                  fontSize: 0,
                  opacity: 0,
                }
          }
        />
      </div>
    );
  };

  const launchFakeLoading = () => {
    setFakeLoading(true);
    setTimeout(() => {
      setFakeLoading(false);
    }, 500);
  };

  const filterScrollBar = useRef(null);

  return (
    <>
      <div className={styles.filtersBar}>
        <div
          className={styles.searchBtn}
          onClick={() => {
            if (!searchClosed) {
              filtersBarSearchForm.resetFields();
              setFilter("");
            }
            setSearchClosed(!searchClosed);
            inputRef.current!.focus({
              cursor: "all",
            });
          }}
        >
          {searchClosed ? (
            <SearchOutlined style={{ fontSize: 22 }} />
          ) : (
            <CloseOutlined style={{ fontSize: 22 }} />
          )}
        </div>
        <div
          className={styles.searchContainer}
          style={searchClosed ? { maxWidth: 0 } : { maxWidth: "100%" }}
        >
          <Form form={filtersBarSearchForm}>
            <FormItem shouldUpdate style={{ marginBottom: 0 }}>
              {() => {
                return (
                  <Search
                    size={"small"}
                    name="filtersBarSearch"
                    ref={inputRef}
                    bordered={false}
                    allowClear
                    loading={fakeLoading}
                    style={{ backgroundColor: "white !important" }}
                    placeholder="Cerca un piatto o un ingrediente..."
                    onChange={(e) => {
                      launchFakeLoading();
                      setFilter(e.target.value);
                    }}
                  />
                );
              }}
            </FormItem>
          </Form>
        </div>
        <Button
          type={"text"}
          style={{ height: "100%" }}
          onClick={() => {
            (filterScrollBar.current as never as Element).scrollLeft -= 100;
          }}
        >
          <CaretLeftOutlined />
        </Button>
        <div ref={filterScrollBar} className={styles.filtersBar}>
          {searchClosed &&
            filters.map((filter) => (
              <FilterPill
                onClick={() => toggleFilter(filter)}
                key={filter}
                label={filter}
                selected={selectedFilters.includes(filter)}
              />
            ))}
        </div>
        <Button
          type={"text"}
          style={{ height: "100%" }}
          onClick={() => {
            (filterScrollBar.current as never as Element).scrollLeft += 100;
          }}
        >
          <CaretRightOutlined />
        </Button>
      </div>
    </>
  );
};

export default FiltersBar;
